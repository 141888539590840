import { Controller } from 'stimulus'

export default class extends Controller {
  fetch(url, options) {
    const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');

    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'X-CSRF-Token': csrfTokenElement !== null ? csrfTokenElement.getAttribute('content') : null
      }
    });
  }
}