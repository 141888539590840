// TODO: convert to Stimulus.js
export default class AlertsController {
  static containerSelector = "#flash";

  constructor(parentController) {
    this.parentController = parentController;
  }

  appendAlert({ message, alertType = "info" }) {
    const $alert = $(`<div class="alert alert-${alertType} fade show">`);
    const $closeButton = $('<a class="close" data-dismiss="alert">&times;</a>');
    $alert.html(message)
      .prepend($closeButton);

    $(this.constructor.containerSelector).prepend($alert);
  }
}
