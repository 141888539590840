import Twine from 'twine';
import Modal from "../admin/modal";
import AlertsController from "../admin/controllers/alerts_controller";
import PricesController from "../admin/controllers/prices_controller";

// TODO: Convert to Stimulus.js

/* 
 * The App class serves as a global supervisor and is available on
 * DocumentReady and for the rest of the lifecycle of the application. It's
 * responsible for coordinating controllers that take responsibilities over
 * certain portions of the application.
 *
 */
export default class App {
  constructor() {
    this.controllers = {};

    this.observeOffCanvas();
    this.trackTabHistory();
    this.highlightMissingTranslationsInDev();
    this.observeToolTips();
    this.initializeBindings();
    this.createControllers();
  }

  observeOffCanvas() {
    $(document).on("click", "[data-toggle=off-canvas]", (e) => {
      let targetSelector = $(e.currentTarget).data('target');
      $(targetSelector).toggleClass('active');
    });
  }

  trackTabHistory() {
    $(document).on("shown.bs.tab", "[data-toggle=tab]", (e) => {
      const href = $(e.target).attr("href");
      history.pushState(null, null, href);
    });

    const activeTabHref = window.location.hash;
    
    if (activeTabHref) {
      $(`.nav-link[href="${activeTabHref}"]`).tab("show");
    }
  }

  observeToolTips() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  highlightMissingTranslationsInDev() {
    $('body.development span.translation_missing').popover({
      content: function() { this.getAttribute('title') },
      trigger: 'hover',
      delay:   { "show": 0, "hide": 2000 }
    });
  }

  initializeBindings() {
    this.context = window.context || {};
    Twine.reset(this.context).bind().refresh();
  }

  createControllers() {
    this.controllers.alerts = new AlertsController(this);
    if ($(PricesController.selector).length) {
      this.controllers.prices = new PricesController(this);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Modals
  ////////////////////////////////////////////////////////////////////////////////
  showModal(...args) {
    this.modal = new Modal(...args);
    this.modal.show();
  }

  hideModal() {
    if (this.modal) {
      this.modal.hide();
      delete this.modal;
    }
  }

  setModalContent(content) {
    this.modal.setContent(content);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Notifications / Alerts
  ////////////////////////////////////////////////////////////////////////////////
  notifySuccess(message) {
    this.controllers.alerts.appendAlert({
      message:   message,
      alertType: "success",
    });
  }

  notifyFailure(message) {
    this.controllers.alerts.appendAlert({
      message:   message,
      alertType: "danger"
    });
  }
}
