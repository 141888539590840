import Croppie from 'croppie';
import 'croppie/croppie.css';

import { DirectUpload } from '@rails/activestorage';

class ProfilePhotoEditorComponent {
  constructor(element) {
    this.element = element;
    this.croppie = null;
    this.file = null;
    this.photoChanged = false;

    this.fileEl = this.element.querySelector("input[type='file']");
    this.previewEl = this.element.querySelector('.preview');
    this.hiddenEl = this.element.querySelector("input[type='hidden']");

    this.directUploadUrl = element.getAttribute('data-upload-url');
    this.fileName = element.getAttribute('data-image-filename');
    this.fileUrl = element.getAttribute('data-image-url');

    this.attachEventListeners();

    if (this.fileUrl) {
      this.initializeCroppie();
    }
  }

  attachEventListeners() {
    this.fileEl.addEventListener('change', this.handleFileChanged.bind(this));
    this.fileEl.form.addEventListener('submit', this.handleFormSubmit.bind(this));
    this.previewEl.addEventListener('change', () => { this.photoChanged = true });
  }

  initializeCroppie() {
    if (this.croppie) {
      this.croppie.bind({ url: this.fileUrl });
    } else {
      this.previewEl.innerHTML = null;
      this.croppie = new Croppie(this.previewEl, {
        url: this.fileUrl,
        enableExif: true,
        viewport: {
          width: 150,
          height: 150,
          type: 'circle'
        },
        boundary: {
          width: 150,
          height: 150
        }
      });
    }
  }

  uploadProfilePhoto() {
    if (this.photoChanged) {
      return new Promise((resolve, reject) => {
        this.croppie.result('blob').then(blob => {
          blob.name = this.fileName;
          const upload = new DirectUpload(blob, this.directUploadUrl);
          upload.create((error, blob) => {
            if (error) {
              reject(error);
            } else {
              resolve(blob);
            }
          });
        });
      });
    } else {
      return Promise.resolve(null);
    }
  }

  handleFileChanged(event) {
    const field = event.target;

    if (field.files && field.files[0]) {
      var reader = new FileReader();
      reader.onload = this.handleImageLoaded.bind(this);
      this.file = field.files[0];
      this.fileName = this.file.name;
      this.photoChanged = true;
      reader.readAsDataURL(field.files[0]);
    }
  }

  handleImageLoaded(event) {
    this.fileUrl = event.target.result;
    this.initializeCroppie();
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const form = event.target;

    this.uploadProfilePhoto().then(blob => {
      if (blob && blob.signed_id) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('value', blob.signed_id);
        hiddenField.name = 'user[photo]'
        form.appendChild(hiddenField);
      }

      form.submit();
    })
  }
}


(function() {
  document.querySelectorAll('.profile-photo-editor-component').forEach(el => {
    new ProfilePhotoEditorComponent(el);
  })
})();