import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [ "form" ]
  static values = {
    stripeKey: String
  }

  connect() {
    this.stripe = Stripe(this.stripeKeyValue);
  }

  async renderForm(fetchClientSecret) {
    const checkout = await this.stripe.initEmbeddedCheckout({
      fetchClientSecret
    })

    checkout.mount(this.formTarget);
  }
}