// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
Rails.start()

import '@enginuitygroup/library';
import "@fortawesome/fontawesome-free/js/all";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap";
import "../accounts/index.sass";
import "../accounts/tailwind.scss";

import App from "../accounts/app.js";

import "../components";
import "../accounts/controllers";

import PhotoPreviewer from "../accounts/PhotoPreviewer";
PhotoPreviewer.start();

const images = require.context('../images', true);

$(function() {
  window.app = new App();
});
