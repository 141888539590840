// TODO: convert to Stimulus.js
export default class PricesController {
  static selector          = "#prices";
  static tableBodySelector = "#prices table tbody";

  constructor(parentController) {
    this.parentController = parentController;
    this.watchForCreateClick();
    this.watchForEditClick();
  }

  watchForCreateClick() {
    const $el = $(this.constructor.selector);

    $el.on("click", "[data-toggle=new-admin-price-modal]", (event) => {
      const $target = $(event.currentTarget);
      const url     = $target.data('url');
      const title   = $target.data('title');

      this.parentController.showModal({
        title: title,
        url:   url,
        id:    "new-admin-price-modal"
      });
    });
  }

  watchForEditClick() {
    const $el = $(this.constructor.tableBodySelector);

    $el.on("click", "[data-toggle=edit-admin-price-modal]", (event) => {
      const $target = $(event.currentTarget);
      const url     = $target.data('url');
      const title   = $target.data('title');

      this.parentController.showModal({
        title: title,
        url:   url,
        id:    "edit-admin-price-modal"
      });
    });
  }

  priceCreationSucceeded({ message, rowHtml }) {
    const $el = $(this.constructor.tableBodySelector);
    $el.append(rowHtml);
    this.parentController.hideModal();
    this.parentController.notifySuccess(message);
  }

  priceCreationFailed({ message, formHtml }) {
    this.parentController.setModalContent(formHtml);
    this.parentController.notifyFailure(message);
  }

  priceUpdateSucceeded({ rowSelector, message, rowHtml }) {
    const $el = $(rowSelector);
    $el.replaceWith(rowHtml);
    this.parentController.hideModal();
    this.parentController.notifySuccess(message);
  }

  priceUpdateFailed(args) {
    this.priceCreationFailed(args);
  }
}
